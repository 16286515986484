import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../enums/utils/languages.enum';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BeUtilsService } from './BE/be-utils.service';
import { PrimeNGConfig } from 'primeng/api';
import {L10n, setCulture} from '@syncfusion/ej2-base';
import { CountryCode } from '../enums/utils/country_codes.enum';

export enum LanguageChangePriority {
  _1 = 1, // pri automatickem nastaveni / predvyplneni
  _2 = 2, // pri nastaveni pres url parametr
  _3 = 3, // pri manualnim nastaveni userem kliknutim
}

@Injectable({
  providedIn: 'root'
})
export class I18nService implements OnDestroy {

  private lsKey = 'LEKTORY_language';
  private countryCodeKey = 'LEKTORY_countryCode';
  
  public countryCode$ = new BehaviorSubject<CountryCode | null>(null);

  defaultLang = Language.EN;

  currentLangPriority: LanguageChangePriority = LanguageChangePriority._1;

  private subs: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private beUtilsService: BeUtilsService,
    private primeNGConfig: PrimeNGConfig,
  ) {
    this.translate.addLangs([Language.CS, Language.EN, Language.SK]);
    this.translate.setDefaultLang(this.defaultLang);

    this.initCountryCode();

    // see if there is a language stored in local storage
    const storedLang = localStorage.getItem(this.lsKey);
    if (storedLang) {
      this.useLanguage(storedLang as Language, LanguageChangePriority._1);
    } else {
      // get country code from ip-api.com

      this.beUtilsService.getLocationInfoByIp().pipe(
        take(1)
      ).subscribe({
        next: (res) => {
          if (res.language) {
            this.useLanguage(res.language, LanguageChangePriority._1);
          } else {
            this.setFallbackLanguage();
          }
          if (res.countryCode) {
            this.setCountryCode(res.countryCode);
          }
        },
        error: (err) => {
          console.error(err);
          this.setFallbackLanguage();
        }
      });
    }

    // watch url parameters if there is a l parameter
    this.route.queryParams.subscribe(params => {
      if (params['l']) {
        this.useLanguage(params['l'], LanguageChangePriority._2);
      }
    });
  }

  private setStoredLanguage(language: Language) {
    localStorage.setItem(this.lsKey, language);
  }

  private setFallbackLanguage() {
    const browserLang = this.translate.getBrowserLang();
    const defaultLang = this.translate.defaultLang;

    this.useLanguage((browserLang || defaultLang) as Language, LanguageChangePriority._1);
  }

  private initCountryCode() {
    const storedCountryCode = localStorage.getItem(this.countryCodeKey) as CountryCode | null;
    if (storedCountryCode) {
      this.setCountryCode(storedCountryCode);
    }
  }
  private setCountryCode(countryCode: CountryCode) {
    this.countryCode$.next(countryCode);
    localStorage.setItem(this.countryCodeKey, countryCode);
  }


  public useLanguage(language: Language, priority: LanguageChangePriority) {
    if (priority < this.currentLangPriority) return;
    this.currentLangPriority = priority;

    let l = this.getLanguageToUse(language);
    if (l === Language.SK) {
      this.translate.setDefaultLang(Language.CS);
    } else {
      this.translate.setDefaultLang(this.defaultLang);
    }
    console.log('useLanguage', l);
    this.translate.use(l);
    // Syncfusion
    setCulture(l);
    // /
    this.setStoredLanguage(language);

    this.document.documentElement.lang = l;

    // PrimeNG
    this.translate.get('primeng').subscribe((res) => {
      this.primeNGConfig.setTranslation(res);
    });
  }

  public getLanguageToUse(language: Language): Language {
    switch (language) {
      case Language.CS:
      case Language.SK:
      case Language.EN:
        return language;
      default:
        return Language.EN;
    }
  }

  public hasTranslation(translationKey: string, language?: string): boolean {
    const translations = this.translate.translations[language || this.translate.currentLang];
    return translations && translations[translationKey] !== undefined;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}
